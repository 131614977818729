<template>
  <header class="home-header">
    <section class="home-header__hgroup">
      <Markdown tag="h1" class="home-header__banner" :text="$prismic.content.headerTitle" />
      <p>{{ $prismic.content.headerSubtitle }}</p>

      <div class="home-header__cta">
        <router-link to="/schedule" class="button">Voir les cours</router-link>
        <router-link to="/contact" class="button -light">Nous contacter</router-link>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'HomeHeader'
}
</script>
