<template>
  <section>
    <ScheduleCalendar />
    <ScheduleCourses />
    <ScheduleBanner />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Schedule',
  components: { ...partials }
}
</script>
