<template>
  <section>
    <HomeHeader />
    <HomeBullet />
    <HomeCourses />
    <HomeInfo />
    <HomeGallery />
    <HomeTestimony />
    <HomeBanner />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Home',
  components: { ...partials }
}
</script>
