<template>
    <TransitionGroup tag="article" class="layout-slider" name="slide">
      <SliderItem
        v-for="slide in activeSlides"
        :key="slide.name"
        v-bind="slide"
        @mouseenter.native="stopSlider"
        @mouseleave.native="startSlider"
      />
    </TransitionGroup>
</template>

<script>
import SliderItem from './SliderItem.vue'

export default {
  name: 'Slider',
  components: { SliderItem },
  props: {
    slides: { type: Array, default: Array }
  },
  data() {
    return {
      activeSlides: [],
      inactiveSlides: [],
      intervalId: null
    }
  },
  watch: {
    slides: {
      immediate: true,
      handler: 'setupSlides'
    }
  },
  methods: {
    getSlideCount() {
      return this.$device.tablet ? 2 : 1
    },
    setupSlides() {
      const slideCount = this.getSlideCount()
      if (this.intervalId) window.clearInterval(this.intervalId)

      if (this.slides.length <= slideCount) {
        this.activeSlides = [...this.slides]
      } else {
        this.inactiveSlides = [...this.slides]
        this.activeSlides = this.inactiveSlides.splice(0, slideCount)
        this.startSlider()
      }
    },
    nextSlide() {
      this.inactiveSlides.push(this.activeSlides.shift())
      this.activeSlides.push(this.inactiveSlides.shift())
    },
    startSlider() {
      if (this.intervalId) window.clearInterval(this.intervalId)
      this.intervalId = window.setInterval(this.nextSlide, 6000)
    },
    stopSlider() {
      window.clearInterval(this.intervalId)
      this.intervalId = null
    }
  }
}
</script>
