<template>
  <article class="layout-section -bg-dark -center -bg" style="background-image: url('/images/cta-2.jpg');">
    <h2 class="layout-section__title">Besoin de plus d'information ? </h2>
    <p>N'hésitez pas à nous écrire pour des questions ou de plus amples informations. Il nous fera un grand plaisir de discuter avec vous !</p>
    <router-link to="/contact" class="button layout-section__cta">Contactez-nous maintenant</router-link>
  </article>
</template>

<script>
export default {
  name: 'ScheduleBanner'
}
</script>
