<template>
  <article class="layout-section -bg-shade">
    <h2 class="layout-section__title">{{ $prismic.content.testimonialsTitle }}</h2>
    <p>{{ $prismic.content.testimonialsText }}</p>
    <Slider :slides="$prismic.content.testimonials || []" />
  </article>
</template>

<script>
import { Slider } from '@/components'

export default {
  name: 'HomeTestimony',
  components: { Slider }
}
</script>
