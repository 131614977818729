<template>
  <Element :tag="tag">
    <slot />
  </Element>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'Markdown',
  props: {
    tag: { type: String, default: 'div' },
    text: { type: [String, Object], default: Object }
  },
  computed: {
    mdContent() {
      const { before = null, after = null } = typeof this.text === 'string' ? { after: this.text } : this.text
      return { afterbegin: before, beforeend: after }
    }
  },
  mounted() {
    this.$nextTick(() =>
      Object.entries(this.mdContent).forEach(([position, content]) => {
        if (content) this.$el.insertAdjacentHTML(position, sanitizeHtml(this.parseMarkdown(content)))
      })
    )
  },
  methods: {
    parseMarkdown(str) {
      return str.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
    }
  }
}
</script>
