<template>
  <article class="layout-section">
    <h2 v-scroll:reveal="{ delay: 500 }" class="layout-section__title">Horaire des cours</h2>
    <p v-scroll:reveal="{ delay: 575 }">{{ signupTimeframe }}</p>

    <img v-scroll:reveal="{ delay: 650 }" :src="scheduleImage" alt="Horaire" class="layout-section__schedule" />
  </article>
</template>

<script>
export default {
  name: 'ScheduleCalendar',
  computed: {
    scheduleImage() {
      const { url } = this.$prismic.content.schedule || {}
      return url || '/images/schedule.jpg'
    },
    signupTimeframe() {
      return this.$prismic.content.signupTimeframe
    }
  }
}
</script>
