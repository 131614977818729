<template>
  <section class="navigation-bread">
    <router-link to="/schedule" class="navigation-bread__back">Liste des cours</router-link>
    <span>/</span>
    <p><slot /></p>
  </section>
</template>

<script>
export default {
  name: 'CourseNav'
}
</script>
