import parallax from './parallax'
import scrollHub from './scroll-hub'

const modules = { parallax }

const triggerUpdate = (delay = 0) => {
  setTimeout(() => {
    scrollHub.triggerUpdate()
  }, delay)
}

const generateUid = () => {
  const gen = () =>
    'xxyyxyxy'.replace(/[xy]/g, char =>
      (char === 'x' ? (Math.random() * 16) | 0 : (((Math.random() * 16) | 0) & 0x3) | 0x8).toString(16)
    )

  let uid = gen()
  while (!scrollHub.isUnique(uid)) uid = gen()
  return uid
}

export default {
  triggerUpdate,
  install(Vue, options) {
    if (options) {
      Object.keys(modules).forEach(module => {
        if (modules[module].options) {
          modules[module].options(options)
        }
      })
    }

    Vue.directive('scroll', {
      bind(node, { arg, value, modifiers }) {
        if (!modules[arg] || !modules[arg].init) return
        modules[arg].init({ uid: generateUid(), node, value, modifiers })
      },
      inserted(node, { arg, value, modifiers }) {
        if (!modules[arg] || !modules[arg].add) return
        const uid = node.dataset.uid || generateUid()
        modules[arg].add({ uid, node, value, modifiers })
      },
      unbind(node, { arg }) {
        if (!modules[arg] || !modules[arg].remove) return
        modules[arg].remove(node.dataset.uid)
      }
    })

    Vue.prototype.$scrollHub = {
      triggerUpdate,
      bind(handler) {
        const uid = generateUid()
        scrollHub.bind(uid, handler)
        return uid
      },
      unbind(uid) {
        scrollHub.unbind(uid)
      }
    }
  }
}
