<template>
  <div ref="scrollbox" class="scrollbox">
    <DynamicTag :is="contentTag" :class="contentClass">
      <slot />
    </DynamicTag>
  </div>
</template>

<script>
import SimpleBar from 'simplebar'

export default {
  name: 'ScrollBox',
  props: {
    config: { type: Object, default: Object },
    contentTag: { type: String, default: 'div' },
    contentClass: { type: [String, Array, Object], default: Array }
  },

  data() {
    return { simplebar: null }
  },

  mounted() {
    this.$nextTick(() => {
      this.simplebar = new SimpleBar(this.$refs.scrollbox, this.config)
    })
  }
}
</script>
