<template>
  <article class="layout-section">
    <section id="contact" class="layout-section__container">
      <h2 class="layout-section__title">{{ $prismic.content.contactTitle }}</h2>
      <p>{{ $prismic.content.contactSubtitle }}</p>

      <ContactFormFields />
    </section>
  </article>
</template>

<script>
import ContactFormFields from "./ContactFormFields.vue"

export default {
  name: 'ContactForm',
  components: { ContactFormFields }
}
</script>
