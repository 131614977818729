export default {
  name: 'Element',
  functional: true,
  props: {
    tag: { type: String, default: null },
    is: { type: String, default: 'div' }
  },
  render: (h, { children, data, props }) =>
    h(props.tag || props.is, { class: [data.staticClass, data.class] }, children)
}
