<template>
  <article class="layout-section -bg-dark -center -bg" style="background-image: url('/images/cta-1.jpg');">
    <h2 class="layout-section__title">Bouger dans le confort de votre foyer</h2>
    <p>Nos cours virtuels sont variés, motivants et abordables</p>

    <div class="layout-section__cta">
      <router-link to="/schedule" class="button">Voir les cours</router-link>
      <router-link to="/contact" class="button -light">Nous contacter </router-link>
    </div>
  </article>
</template>

<script>
export default {
  name: 'HomeBanner'
}
</script>
