<template>
  <section class="layout-section -bg-shade">
    <article class="layout-section__hgroup">
      <h2 class="layout-section__title">{{ course.name }}</h2>
      <p v-for="text in course.longText" :key="text">{{ text }}</p>
    </article>

    <article class="section-table">
      <section class="section-table__row">
        <div class="section-table__item">Horaire</div>
        <div class="section-table__item -large">{{ course.schedule }}</div>
      </section>

      <section class="section-table__row">
        <div class="section-table__item">Lieu</div>
        <div class="section-table__item -large">{{ course.location }}</div>
      </section>

      <section class="section-table__row">
        <div class="section-table__item">Niveau</div>
        <div class="section-table__item -large">{{ course.level }}</div>
      </section>

      <section class="section-table__row">
        <div class="section-table__item">Instructeur</div>
        <div class="section-table__item -large">{{ course.instructor }}</div>
      </section>

      <section class="section-table__row">
        <div class="section-table__item">Matériel</div>
        <div class="section-table__item -large">{{ course.material }}</div>
      </section>

      <section class="section-table__row">
        <div class="section-table__item">Prix</div>
        <div class="section-table__item -large">{{ course.price }}</div>
      </section>
    </article>

    <article v-if="$prismic.content.signupEnabled" class="section-table__button">
      <a :href="$prismic.content.signupLink" target="_blank" rel="noopener" class="button">M'inscrire maintenant</a>
    </article>
  </section>
</template>

<script>
export default {
  name: 'CourseInfo',
  props: {
    course: { type: Object, required: true }
  }
}
</script>
