<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 111.67" class="navigation-top__logo ">
    <path d="M417.78,72.87l8.71-57.72h17.77v81.3H432.18V38.15l-8.83,58.3H411.27L401.75,39V96.45H390.6V15.15h17.77Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <path d="M477.93,49.41h17.54V61H477.93V84.83H500V96.45H465.16V15.15H500V26.76H477.93Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <path d="M16.72,64l-3.95,7.44v25H0V15.15H12.77V50.57L29.5,15.15H42.27L24.5,51.38,42.27,96.45H29.15Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <path d="M59.69,15.15H72.47v81.3H59.69Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <path d="M104.75,37.56V96.45H93.25V15.15h16L122.4,63.81V15.15h11.39v81.3H120.66Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <path d="M167.35,49.41h17.53V61H167.35V84.83h22.06V96.45H154.57V15.15h34.84V26.76H167.35Z" transform="translate(0 0.07)" style="fill:#2a3241"/>
    <rect x="154.5" width="34.92" height="8.13" style="fill:#facd45;"/>
    <path d="M279.56,34v43.9c0,5.81,2.56,8,6.62,8s6.62-2.2,6.62-8V34c0-5.8-2.56-8-6.62-8S279.56,28.19,279.56,34Z" transform="translate(0 0.07)" style="fill:#facd45"/>
    <path d="M344.36,26.91h-6.15v25h5c4.76,0,7.67-2.09,7.67-8.6v-8C350.87,29.46,348.89,26.91,344.36,26.91Z" transform="translate(0 0.07)" style="fill:#facd45"/>
    <path d="M202.79-.07V111.6H377.11V-.07ZM342.62,63.49h-4.41v33.1H325.43V15.29h19.28c13.24,0,18.93,6.16,18.93,18.7v6.39c0,8.36-2.67,13.82-8.36,16.49,6.39,2.67,8.48,8.83,8.48,17.3V86.72c0,4,.11,6.85,1.39,9.87h-13c-.69-2.09-1.16-3.37-1.16-10V73.83C351,66.28,348.43,63.49,342.62,63.49ZM305.57,34.81V77.08c0,13-6.85,20.44-19.39,20.44s-19.39-7.43-19.39-20.44V34.81c0-13,6.85-20.44,19.39-20.44S305.57,21.8,305.57,34.81Zm-60,16.6V63H229V96.59H216.27V15.29h33.79V26.91H229v24.5Z" transform="translate(0 0.07)" style="fill:#facd45"/>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
