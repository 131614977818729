import pace from 'pace-progress'
import http from 'axios'

pace.start()

pace.once('hide', () => {
  window.prerenderReady = true
})

http('/icons.svg').then(({ data }) => document.body.insertAdjacentHTML('afterbegin', data))
