<template>
  <menu v-if="!$device.laptop && isVisible" class="navigation-side">
    <ul class="navigation-side__content">
      <figure class="navigation-side__logo">
        <img src="/images/logo-light.svg" alt=""/>
      </figure>

      <RouterLink
        v-for="({ label, ...link }, i) in $prismic.navLinks"
        :key="label"
        :to="link"
        :exact="!i"
        class="navigation-side__item"
        @click.native="$emit('hide-sidenav')">
        {{ label }}
      </RouterLink>

      <ActionButton href="https://calendly.com/kineforme" label="Prendre rendez-vous" @click.native="$emit('hide-sidenav')" />

      <section class="navigation-side__social">
        <figure class="navigation-side__icon">
          <svg-icon id="social-facebook" />
        </figure>

        <figure class="navigation-side__icon">
          <svg-icon id="social-instagram" />
        </figure>
      </section>
    </ul>

    <button class="navigation-side__close" @click="$emit('hide-sidenav')" />
  </menu>
</template>

<script>
export default {
  name: 'NavigationSide',
  props: {
    isVisible: { type: Boolean, default: false }
  }
}
</script>
