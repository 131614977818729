<template>
  <section v-if="course">
    <CourseNav>{{ course.name }}</CourseNav>
    <CourseInfo :course="course" />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Course',
  components: { ...partials },
  props: {
    slug: { type: String, default: null }
  },
  computed: {
    course() {
      return this.$prismic.courses[this.slug]
    }
  },
  beforeMount() {
    if (!this.course) this.$router.replace({ name: 'schedule' })
  }
}
</script>
