<template>
  <section class="slide layout-slider__item">
    <p class="layout-slider__info -quote">{{ text }}</p>
    <p class="layout-slider__info">{{ name }}</p>
  </section>
</template>

<script>
export default {
  name: 'SliderItem',
  props: {
    name: { type: String, default: null },
    text: { type: String, default: null }
  }
}
</script>
