<template>
  <section>
    <ContactForm />
    <ContactTeam />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Contact',
  components: { ...partials }
}
</script>
