import axios from 'axios'

const { encodeURI } = window

export const createCachedRef = endpoint => {
  let cachedRef = null

  return () =>
    cachedRef
      ? Promise.resolve(cachedRef)
      : axios(endpoint).then(({ data = {} }) => {
          const { refs = [] } = data
          const { ref } = refs.find(({ id }) => id === 'master') || {}
          cachedRef = ref
          return ref
        })
}

export const getQueryString = query =>
  encodeURI(
    Object.entries(query)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
