<template>
  <article class="layout-section -bg-shade">
    <div class="layout-section__hgroup">
      <h2 class="layout-section__title">{{ $prismic.content.teamTitle }}</h2>
      <p>{{ $prismic.content.teamText }}</p>
    </div>

    <ContactTeamEmployee v-for="member in $prismic.content.team" :key="member.title" v-bind="member" />
  </article>
</template>

<script>
import ContactTeamEmployee from './ContactTeamEmployee.vue'

export default {
  name: 'ContactTeam',
  components: { ContactTeamEmployee }
}
</script>
