<template>
  <footer class="layout-section -bg-dark navigation-footer">
      <article class="navigation-footer__row">
        <ul class="navigation-footer__list">
          <router-link to="/" class="navigation-footer__item">Accueil / À propos</router-link>
          <router-link to="/schedule" class="navigation-footer__item">Cours</router-link>
          <router-link to="/contact" class="navigation-footer__item">Nous contacter</router-link>
        </ul>

        <ul class="navigation-footer__list">
          <a :href="`mailto:${$prismic.content.contactEmail}`" class="navigation-footer__item">{{ $prismic.content.contactEmail }}</a>
          <li class="navigation-footer__item">{{ $prismic.content.contactPhone }}</li>
        </ul>

        <ul class="navigation-footer__list">
          <a href="https://www.facebook.com/Kin%C3%A9forme-103555528149649" target="_blank" rel="noopener" class="navigation-footer__item">Facebook</a>
          <a href="https://www.instagram.com/kinefor.me/" target="_blank" rel="noopener" class="navigation-footer__item">Instagram</a>
        </ul>
      </article>

      <p class="navigation-footer__copyright">&copy; {{ currentYear }} Kinéforme 	<span>|</span> Un site créé par <a href="https://medioze.com/" target="_blank">Medioze</a></p>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
