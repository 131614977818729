import http from 'axios'

import { createCachedRef, getQueryString } from './helpers'

export default (Vue, endpoint) => {
  const getRef = createCachedRef(endpoint)

  const PrismicVM = new Vue({
    name: 'Prismic',
    data() {
      return {
        isReady: false,
        content: {},
        courses: {}
      }
    },
    computed: {
      navLinks() {
        const { aboutLabel, servicesLabel, teamLabel, testimonialsLabel, contactLabel } = this.content

        return [
          { name: 'home', label: aboutLabel, hash: '#about' },
          { name: 'home', label: servicesLabel, hash: '#service' },
          { name: 'home', label: teamLabel, hash: '#team' },
          { name: 'home', label: testimonialsLabel, hash: '#testimonials' },
          { name: 'home', label: contactLabel, hash: '#contact' }
        ]
      }
    },
    created() {
      getRef().then(ref => {
        const queries = [
          getQueryString({ ref, q: `[[at(document.type,"content")]]` }),
          getQueryString({ ref, q: `[[at(document.type,"course")]]` })
        ]

        Promise.all(queries.map(query => http(`${endpoint}/documents/search?${query}`).then(({ data }) => data))).then(
          ([contentData, courseData]) => {
            const { results: contentResults = [] } = contentData
            const { results: courseResults = [] } = courseData

            this.content = this.parseContent(contentResults)
            this.courses = this.parseCourses(courseResults)
            this.isReady = true
          }
        )
      })
    },
    methods: {
      parseContent([{ data: content = {} }]) {
        return Object.keys(content)
          .filter(key => /^[^_]/.test(key))
          .reduce(
            (contentMap, key) => ({
              ...contentMap,
              [key.replace(/-(\w)/g, ($m, $1) => $1.toUpperCase())]: content[key]
            }),
            {}
          )
      },
      parseCourses(courseData) {
        return courseData.reduce((courseMap, { data, slugs }) => {
          const { image, short_text, long_text, slug, ...course } = data
          const [shortText] = short_text.map(({ text }) => text)
          const longText = long_text.map(({ text }) => text)
          const [fallbackSlug] = slugs
          const slugString = slug || fallbackSlug

          return {
            ...courseMap,
            [slugString.toLowerCase()]: {
              ...course,
              shortText,
              longText,
              slug,
              fallbackSlug,
              image: image.url
            }
          }
        }, {})
      }
    }
  })

  Vue.prototype.$prismic = PrismicVM
}
