<template>
  <article class="layout-form">
    <h3 v-if="wasSubmitted" class="layout-section__title">Votre message a été envoyé!</h3>

    <section v-else class="layout-form__section" :style="formStyle">
      <fieldset class="layout-form__fieldset">
        <input v-model="model.name" type="text" placeholder="Nom Complet" :class="getFieldClassName('name')" />
        <input v-model="model.email" type="email" placeholder="Adresse Courriel" :class="getFieldClassName('email')" />
        <input v-model="model.subject" type="text" placeholder="Sujet" class="input" />
      </fieldset>

      <fieldset class="layout-form__fieldset">
        <textarea v-model="model.message" placeholder="Message" :class="getFieldClassName('message', '-textarea')" />
      </fieldset>
    </section>

    <section class="layout-form__section -button">
      <button class="button -alt" @click="onSubmit">Envoyer</button>
    </section>
  </article>
</template>

<script>
import { sendEmail, validateEmail, validateRequiredField } from '@/helpers'

const model = {
  name: '',
  email: '',
  subject: '',
  message: ''
}

const modelValidators = {
  name: validateRequiredField,
  email: validateEmail,
  message: validateRequiredField
}

export default {
  name: 'ContactFormFields',
  data() {
      return {
        model: { ...model },
        wasSubmitted: false,
        showValidation: false,
        isProcessing: false
      }
    },
    computed: {
      invalidFields() {
        return Object.keys(modelValidators).filter(field => !modelValidators[field](this.model[field]))
      },
      isValid() {
        return !this.invalidFields.length
      },
      formStyle() {
        return this.isProcessing ? { pointerEvents: 'none', opacity: 0.5 } : {}
      }
    },
    methods: {
      getFieldClassName(key, className) {
        return ['input', className, { '-invalid': this.showValidation && this.invalidFields.indexOf(key) >= 0 }]
      },
      clear() {
        this.model = { ...model }
      },
      onSubmit() {
        this.showValidation = true
        if (!this.isValid || this.isProcessing || this.wasSubmitted) return
        this.isProcessing = true
        return sendEmail({
          subject: 'Nouveau contact - kinefor.me',
          recipient: 'stephanie.kinesio@gmail.com',
          ...this.model
        })
          .then(this.onSubmitSuccess)
          .catch(err => err)
      },
      onSubmitSuccess() {
        this.showValidation = false
        this.isProcessing = false
        this.wasSubmitted = true
        this.clear()
      }
    }
  }
</script>
