<template>
  <article class="layout-gallery">
    <div class="layout-gallery__item" style="background-image: url('/images/gallery-1.jpg');" />
    <div class="layout-gallery__item" style="background-image: url('/images/gallery-2.png');" />
    <div class="layout-gallery__item" style="background-image: url('/images/gallery-3.png');" />
    <div class="layout-gallery__item" style="background-image: url('/images/gallery-5.jpg');" />
    <div class="layout-gallery__item" style="background-image: url('/images/panel-2.jpg');" />
    <div class="layout-gallery__item" style="background-image: url('/images/gallery-6.jpg');" />
  </article>
</template>

<script>
export default {
  name: 'HomeGallery'
}
</script>
