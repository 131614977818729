<template>
  <article class="layout-section -bg-dark -center">
    <template v-if="$prismic.content.signupEnabled">
      <h2 class="layout-section__title">Inscriptions ouvertes</h2>
      <h2 class="layout-section__title">{{ $prismic.content.signupTimeframe }}</h2>
      <p>Réservez votre place dès maintenant au cours de votre choix afin de venir bouger avec nous !</p>
      <router-link to="/schedule" class="button layout-section__cta">Voir l'horaire</router-link>
    </template>

    <template v-else>
      <h2 class="layout-section__title">Aucune inscription ouverte pour le moment.</h2>
      <p>Restez à l'affut sur les réseaux sociaux</p>
    </template>
  </article>
</template>

<script>
export default {
  name: 'HomeInfo'
}
</script>
