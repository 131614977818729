import './sass/app.scss'
import './startup'

import Vue from 'vue'
import DeviceQueries from 'vue-device-queries'

import App from './App.vue'
import router from './router'
import Prismic from './prismic'
import { scrollEvents, scrollHub } from './plugins'
import { GlobalComponents } from './components'
import { toRem } from './helpers'

Vue.config.productionTip = false

Vue.use(scrollHub)
Vue.use(scrollEvents)
Vue.use(GlobalComponents)
Vue.use(Prismic, 'https://kineforme.cdn.prismic.io/api/v2')
Vue.use(DeviceQueries, { laptop: `min-width: ${toRem(1088)}`, tablet: `min-width: ${toRem(768)}` })

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
