<template>
  <article class="layout-section -bg-shade -split">
    <figure class="layout-section__figure" style="background-image: url('/images/workout-zoom-1.jpg');" />

    <section class="layout-section__split">
      <h2 class="layout-section__title">Cours virtuels via la plateforme Zoom</h2>
      <p>Période en cours : {{ $prismic.content.signupTimeframe }}</p>

      <nav class="grid-cta">
        <router-link
          v-for="{ slug, name } in courses"
          :key="slug"
          :to="{ name: 'course', params: { slug } }"
          class="grid-cta__item">
          {{ name }}
          <svg-icon id="arrow" />
        </router-link>
      </nav>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeCourses',
  computed: {
    courses() {
      return this.$prismic.courses
    }
  }
}
</script>
