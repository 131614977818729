<template>
  <article class="layout-section -bg-shade">
    <h2 class="layout-section__title">Liste des cours</h2>

    <section class="section-grid">
      <router-link
        v-for="{ slug, image, name, shortText } in courses"
        :key="slug"
        :to="{ name: 'course', params: { slug } }"
        class="section-grid__item">
        <figure class="section-grid__figure" :style="getBackgroundImage(image)" />

        <div class="section-grid__content">
          <h3 class="section-grid__heading">{{ name }}</h3>
          <p>{{ shortText }}</p>
        </div>
      </router-link>
    </section>
  </article>
</template>

<script>
export default {
  name: 'ScheduleCourses',
  computed: {
    courses() {
      return this.$prismic.courses
    }
  },
  methods: {
    getBackgroundImage(image) {
      if (!image) return
      return { backgroundImage: `url('${image}')` }
    }
  }
}
</script>
