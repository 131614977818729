import scroll from 'scroll'

const scrollEventTarget = document
const { scrollingElement } = document
const { requestAnimationFrame } = window

export default Vue => {
  Vue.prototype.$scrollEvents = new Vue({
    name: 'ScrollEventsVM',
    data() {
      return { scrollingElement }
    },
    methods: {
      triggerEvent(eventName, payload = null) {
        this.$emit(eventName, payload)
      },
      onEvent(eventName, handler) {
        return this.$on(eventName, handler)
      },
      onScroll(handler) {
        let isQueued = false

        const queueHandler = () => {
          handler(scrollingElement)
          isQueued = false
        }

        const onScroll = () => {
          if (isQueued) return
          requestAnimationFrame(queueHandler)
          isQueued = true
        }

        scrollEventTarget.addEventListener('scroll', onScroll)
        return () => scrollEventTarget.removeEventListener('scroll', onScroll)
      },
      to(targetPosition = 0, offset = 0, duration) {
        if (duration) scroll.top(this.scrollingElement, targetPosition + offset, { duration })
        else this.scrollingElement.scrollTop = targetPosition + offset
      },
      toTop(offset, duration) {
        this.to(0, offset, duration)
      },
      toBottom(offset, duration) {
        const { scrollHeight = 0 } = this.scrollingElement
        this.to(scrollHeight, offset, duration)
      },
      toTarget(target, offset, duration) {
        const targetElement = typeof target === 'string' ? this.scrollingElement.querySelector(target) : target
        if (!targetElement) return

        const { scrollTop } = this.scrollingElement
        this.to(scrollTop + targetElement.getBoundingClientRect().top, offset, duration)
      }
    }
  })
}
