<template>
  <section class="layout-section -bg-dark">
    <article class="layout-grid">
      <section
        v-for="{ icon, title, text } in $prismic.content.infoList.slice(0, 3)"
        :key="title"
        class="layout-grid__box">
        <figure class="layout-grid__figure">
          <svg-icon :id="icon" />
        </figure>

        <h3 class="layout-grid__title">{{ title }}</h3>
        <p>{{ text }}</p>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'HomeBullet'
}
</script>
