<template>
  <article class="layout-card">
    <figure class="layout-card__figure" :style="{ backgroundImage: `url(${image.url})` }" />

    <section class="layout-card__content">
      <h4 class="layout-card__heading">{{ title }}</h4>
      <p>{{ text }}</p>

      <a href="https://calendly.com/kineforme" target="_blank" rel="noopener" class="button -alt -small layout-section__cta">
        Prendre un rendez-vous
      </a>
    </section>
  </article>
</template>

<script>
export default {
  name: 'ContactTeamEmployee',
  props: {
    image: { type: Object, default: () => ({ url: null }) },
    title: { type: String, default: null },
    text: { type: String, default: null }
  }
}
</script>
