export const sendEmail = ({ email, name, subject, message, recipient }) =>
  new Promise((resolve, reject) => {
    const http = new XMLHttpRequest()
    http.open('POST', 'https://mailservice.medioze.com/', true)

    http.addEventListener('load', () => {
      if (http.status >= 200 && http.status < 400) return resolve()
      reject(new Error({ message: 'Error posting email', http }))
    })

    const formData = new FormData()
    formData.append('email', email)
    formData.append('subject', `Nouveau contact | kinefor.me | ${subject}`)
    formData.append('message', message)
    formData.append('fullname', name)
    formData.append('to', recipient)
    http.send(formData)
  })
