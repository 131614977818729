export default {
  name: 'SvgIcon',
  functional: true,
  props: {
    id: {
      type: String,
      required: true
    }
  },
  render: (h, { props, data }) => {
    return h('svg', {
      class: ['icon', data.staticClass],
      domProps: { innerHTML: `<use xlink:href="#${props.id}"/>` }
    })
  }
}
