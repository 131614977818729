import scrollHub from './scroll-hub'

const defaults = {
  ratio: 0.25,
  offset: 0
}

export default {
  add({ uid, node, value = {} }) {
    const config = Object.assign(defaults, value)
    const { refNode = node.offsetParent, offset = defaults.offset } = value
    const xPos = `${value.xPos || 0},`
    const prefix = `translate3d(${xPos}`
    const suffix = 'px,0)'
    const ratio = config.ratio * -1
    let buffer, top

    const updater = {
      requestUpdate() {
        top = refNode.getBoundingClientRect().top - offset
        if (!buffer) requestAnimationFrame(updater.updateNode)
        buffer = true
      },
      updateNode() {
        const pos = top * ratio
        node.style.transform = prefix + pos + suffix
        buffer = false
      }
    }

    updater.requestUpdate()
    node.dataset.uid = uid
    scrollHub.bind(uid, updater.requestUpdate)
  },
  remove(uid) {
    scrollHub.unbind(uid)
  },
  options({ parallax }) {
    if (!parallax) return
    if (parallax.ratio) defaults.ratio = parallax.ratio
    if (parallax.offset) defaults.offset = parallax.offset
  }
}
