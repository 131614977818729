<template>
  <div v-if="$prismic.isReady" class="layout">
    <Transition name="layout__view" mode="out-in" @after-leave="setScrollPosition" @enter="updateScrollPosition">
      <RouterView :key="$route.name" class="layout__view" />
    </Transition>

    <NavigationFooter />
    <NavigationTop @toggle-sidenav="isSideNavVisible = !isSideNavVisible"/>
    <NavigationSide :is-visible="isSideNavVisible" @hide-sidenav="isSideNavVisible = false" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isSideNavVisible: false,
      scrollPosition: 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (!to.hash) return
        const duration = !from ? 400 : 0
        setTimeout(() => this.$scrollEvents.toTarget(to.hash, -20, duration), duration)
      }
    }
  },
  methods: {
    updateScrollPosition() {
      this.$nextTick(() => {
        const { hash } = this.$route
        if (hash) return setTimeout(() => this.$scrollEvents.toTarget(hash, 80, 350), 150)
        this.$scrollEvents.to(this.scrollPosition)
      })
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
      this.$scrollEvents.to(0)
    }
  }
}
</script>
