<template>
  <a v-if="href" :href="href" target="_blank" rel="noopener" class="button">
    <slot />
    {{ label }}
  </a>

  <RouterLink v-else-if="to" :to="to" class="button">
    <slot />
    {{ label }}
  </RouterLink>

  <button v-else class="button">
    <slot />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    label: { type: String, default: '' },
    href: { type: String, default: null },
    to: { type: Object, default: null }
  }
}
</script>
