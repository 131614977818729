const sub = {
  // lazy email validation
  email: /^[^\s\n]+@[^\s\n]+\.[a-z0-9]{2,}$/,
  // format to (###) ###-####
  phonePrefixes: [/^(\d{1,3})(\d{1,3})?(\d*)/g, '($1) $2-$3'],
  nonDigits: [/\D/g, '']
}

export const formatPhoneNumber = num =>
  String(num)
    .replace(...sub.nonDigits)
    .replace(...sub.phonePrefixes)

export const validateEmail = value => sub.email.test(value)

export const validateRequiredField = value => !!value.length
