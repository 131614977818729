import Vue from 'vue'
import VueRouter from 'vue-router'
import { Home, Schedule, Contact, Course } from '@/views'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  scrollBehavior: (to, from, savedPosition) => savedPosition,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: Schedule
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/course/:slug?',
      name: 'course',
      component: Course,
      props: true
    }
  ]
})

export default router
